import * as React from "react";
import {
  initialState as userMenuInitialState,
  useUserMenusActions,
} from "@gada-saas/web-core/user/menus/slice";
import { useGetUserInfoQuery } from "@gada-saas/web-core/user";
import { useRouter } from "next/router";
import { apiSauce } from "@gada-saas/web-core/common/api";
import {
  clearIsLoggedIn,
  clearUserToken,
} from "@gada-saas/web-core/common/utils";
import { useLogoutMutation } from "@gada-saas/web-core/auth/otp";
import { Pages } from "@common/constants";
import { useLogoutUserParam } from "./types";
import { useDispatch } from "react-redux";

import { globalResetAction } from "@gada-saas/web-core/common/redux/actions";
import TodoApi from "@gada-saas/web-core/example/todo-rtk-query/hooks";
import CartApi from "@gada-saas/web-core/pos/cart/hooks";
import POSInventoriesApi from "@gada-saas/web-core/pos/inventories/hooks";
import CustomerApi from "@gada-saas/web-core/crm/hooks";
import OrderApi from "@gada-saas/web-core/pos/order/hooks";
import EmployeeApi from "@gada-saas/web-core/employee-access/hooks";
import InventoryListApi from "@gada-saas/web-core/inventory/list/hooks";
import OtpApi from "@gada-saas/web-core/auth/otp/hooks";
import RegistrationApi from "@gada-saas/web-core/auth/registration/hooks";
import CustDebtReportApi from "@gada-saas/web-core/report/customer-debt/hooks";
import CuratedProductsApi from "@gada-saas/web-core/inventory/common/apis/InventoryApi";
import StoreApi from "@gada-saas/web-core/stores/hooks";
import SalesCategoryApi from "@gada-saas/web-core/report/sales-category/hooks";
import SalesApi from "@gada-saas/web-core/report/sales/hooks";
import SalesProductApi from "@gada-saas/web-core/report/sales-product/hooks";
import ProfitReportApi from "@gada-saas/web-core/report/profit/hooks";
import DebtReceivablesApi from "@gada-saas/web-core/report/debt-receivables/hooks";
import ExpenseApi from "@gada-saas/web-core/report/expense/hooks";
import InventoryBulkEditApi from "@gada-saas/web-core/inventory/bulk-edit/hooks";
import ConsignmentDebtApi from "@gada-saas/web-core/report/consignment-debt/hooks";
import BuyingApi from "@gada-saas/web-core/inventory/buying/hooks";
import SupplierApi from "@gada-saas/web-core/supplier/hooks";
import DeliveryOrderApi from "@gada-saas/web-core/report/delivery-order/common/hooks";
import TaxApi from "@gada-saas/web-core/report/tax/hooks";
import StockOpnameApi from "@gada-saas/web-core/stock-opname/hooks";
import InventoryHistoryApi from "@gada-saas/web-core/inventory/history/hooks";
import OfflineTransactionApi from "@gada-saas/web-core/report/offline-transaction/hooks";
import UserApi from "@gada-saas/web-core/user/hooks";
import CoachMarkApi from "@gada-saas/web-core/coach-mark/hooks";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";
import { useTour } from "@reactour/tour";
import { useNotifications } from "@common/context/NotificationsProvider";
import { useRouteHistory } from "@common/context/RouteHistoryProvider/RouteHistoryProvider";

const defaultParam: useLogoutUserParam = {
  skipRefetch: false,
};

export function useLogoutUser() {
  const router = useRouter();
  const { refetch } = useGetUserInfoQuery();
  const UserMenuActions = useUserMenusActions();
  const [logoutFromServer] = useLogoutMutation();
  const dispatch = useDispatch();
  const analytics = useSegmentAnalytics();
  const { isOpen, setIsOpen } = useTour();
  const brazeSdk = useNotifications();
  const { setHistory } = useRouteHistory();

  const clearReduxApiState = React.useCallback(() => {
    dispatch(TodoApi.util.resetApiState());
    dispatch(CartApi.util.resetApiState());
    dispatch(POSInventoriesApi.util.resetApiState());
    dispatch(CustomerApi.util.resetApiState());
    dispatch(OrderApi.util.resetApiState());
    dispatch(EmployeeApi.util.resetApiState());
    dispatch(InventoryListApi.util.resetApiState());
    dispatch(OtpApi.util.resetApiState());
    dispatch(RegistrationApi.util.resetApiState());
    dispatch(CustDebtReportApi.util.resetApiState());
    dispatch(CuratedProductsApi.util.resetApiState());
    dispatch(StoreApi.util.resetApiState());
    dispatch(SalesCategoryApi.util.resetApiState());
    dispatch(SalesApi.util.resetApiState());
    dispatch(SalesProductApi.util.resetApiState());
    dispatch(ProfitReportApi.util.resetApiState());
    dispatch(DebtReceivablesApi.util.resetApiState());
    dispatch(ExpenseApi.util.resetApiState());
    dispatch(InventoryBulkEditApi.util.resetApiState());
    dispatch(ConsignmentDebtApi.util.resetApiState());
    dispatch(BuyingApi.util.resetApiState());
    dispatch(SupplierApi.util.resetApiState());
    dispatch(DeliveryOrderApi.util.resetApiState());
    dispatch(TaxApi.util.resetApiState());
    dispatch(StockOpnameApi.util.resetApiState());
    dispatch(InventoryHistoryApi.util.resetApiState());
    dispatch(OfflineTransactionApi.util.resetApiState());
    dispatch(UserApi.util.resetApiState());
    dispatch(CoachMarkApi.util.resetApiState());
  }, [dispatch]);

  const unregisterPushPermissions = React.useCallback(async () => {
    if (brazeSdk?.isBrazeInit) {
      const { unregisterPush, isPushPermissionGranted } = await import(
        /* webpackExports: ["unregisterPush"] */
        "@braze/web-sdk"
      );
      if (isPushPermissionGranted()) {
        unregisterPush();
      }
    }
  }, [brazeSdk?.isBrazeInit]);

  const handleClickLogout = React.useCallback(
    (parameter?: useLogoutUserParam) => {
      const { skipRefetch } = parameter ?? defaultParam;
      localStorage.setItem(
        "reduxPersist:userMenus",
        JSON.stringify(userMenuInitialState)
      );

      // empty inventory bulk add data to avoid data security leak
      localStorage.removeItem("bulk_add_inventory_form");

      dispatch(globalResetAction());
      unregisterPushPermissions();
      clearReduxApiState();
      clearUserToken();
      clearIsLoggedIn();
      analytics.reset();
      if (isOpen) {
        setIsOpen(false);
      }

      delete apiSauce.headers["Authorization"];
      UserMenuActions.setActiveStore(userMenuInitialState.activeStore);
      if (!skipRefetch) {
        refetch();
      }

      logoutFromServer().then(() => {
        router.push(Pages.REGISTRATION).then(() => {
          // Reset history on logout
          setHistory([Pages.REGISTRATION]);
        });
      });
    },
    [
      dispatch,
      unregisterPushPermissions,
      clearReduxApiState,
      analytics,
      isOpen,
      UserMenuActions,
      logoutFromServer,
      setIsOpen,
      refetch,
      router,
      setHistory,
    ]
  );

  return handleClickLogout;
}
