import * as React from "react";
import { useUserStorePermission } from "@common/hooks/useUserStorePermission";
import { PERMISSION_LIST } from "@common/constants/permissionList";
import {
  getIsLoggedIn,
  iStoreRoleList,
  useUserMenusState,
} from "@gada-saas/web-core";
import { useCachedUserInfo } from "@gada-saas/web-core/user";
import { useModal, useUserStoreRole, useWindowFocus } from "@common/hooks";
import { useRouter } from "next/router";
import { Pages } from "@common/constants";
import { LinearProgress } from "@material-ui/core";
import { iGetUserInfoErrorResponse } from "@gada-saas/web-core/user/types";
import hoistNonReactStatics from "hoist-non-react-statics";
import { useLogoutUser } from "@miscellaneous/navigation/components/UserSettingsPopover/useLogoutUser";
import { Text } from "@gada-saas/web-ui";
import { usePageNameTrackingDataContext } from "@miscellaneous/tracking/contexts/PageNameTrackingContext";
import { useTour } from "@reactour/tour";

type withAuthProtectionParam = {
  pageName: string;
  pagePermissions: Array<PERMISSION_LIST>;
  pageRoles?: Array<iStoreRoleList["name"]>;
};

export function withAuthProtection<P extends object>(
  withAuthParam: withAuthProtectionParam
) {
  return function (Component: React.ComponentType<P>): React.VFC<P> {
    const ProtectedPage = (props: P) => {
      const router = useRouter();
      const GetUserInfoQuery = useCachedUserInfo();
      const logoutUser = useLogoutUser();
      const userStoreRole = useUserStoreRole();
      const { activeStore } = useUserMenusState();
      const [storePermission, hasPermission] = useUserStorePermission();
      const [isAuthorized, setIsAuthorized] = React.useState(false);
      const regex = new RegExp(`^${Pages.HOME}$`, "i");
      const isHomePage = regex.test(router.asPath);
      const error = GetUserInfoQuery.error as iGetUserInfoErrorResponse;
      const isFetching = GetUserInfoQuery.isFetching;
      const isWindowFocused = useWindowFocus();
      const { Modal, showModal } = useModal();
      const { name, setPageName } = usePageNameTrackingDataContext();
      const { isOpen, setIsOpen } = useTour();

      /**
       * Effects
       */
      // Listen to focusWindow
      // use-case: Log out in one of the tabs
      React.useEffect(() => {
        if (isWindowFocused) {
          if (!getIsLoggedIn()) {
            if (isOpen) {
              setIsOpen(false);
            }
            showModal({
              title: "Sesi anda telah habis",
              content: (
                <Text variant="body1">
                  Silahkan login lagi untuk melanjutkan pekerjaan anda.
                </Text>
              ),
              primaryButtonText: "Ok, mengerti",
              onClickPrimaryButton: () => logoutUser({ skipRefetch: true }),
            });
          }
        }
      }, [isOpen, isWindowFocused, logoutUser, setIsOpen, showModal]);

      // Set pagename accordingly
      React.useEffect(() => {
        const prevPage = name;
        setPageName(withAuthParam.pageName);
        return () => {
          setPageName(prevPage);
        };
      }, [name, setPageName]);

      // Listen to Error
      React.useEffect(() => {
        if (isFetching) {
          return;
        }
        if (!error) {
          return;
        }

        if (
          error.status === 404 ||
          error.status === 403 ||
          error.status === 500
        ) {
          logoutUser({ skipRefetch: true });
        }

        if (error.data.problem === "NETWORK_ERROR") {
          router.push(Pages.REGISTRATION);
        }

        if (error.status === 401) {
          if (!isHomePage) {
            if (error.data.data?.errorCode === "NO_TOKEN") {
              router.push({ pathname: Pages.HOME });
            } else {
              router.push({
                pathname: Pages.HOME,
                query: { unauthorized: true },
              });
            }
          } else {
            logoutUser({ skipRefetch: true });
          }
        }
      }, [error, isHomePage, router, isFetching, logoutUser]);

      // Check against store permission
      React.useEffect(() => {
        if (isFetching) {
          return;
        }

        if (storePermission.length === 0) {
          return;
        }

        const { pagePermissions } = withAuthParam;

        // Checking user to have all permission required to open the page (AND Logic)
        const hasAllPermission = pagePermissions.reduce(
          (permissionStatus, requiredPermission) => {
            return permissionStatus && hasPermission([requiredPermission]);
          },
          true
        );

        const hasCommonPermission = hasPermission([PERMISSION_LIST.COMMON]);

        if (!hasCommonPermission) {
          logoutUser({ skipRefetch: true });
        } else if (!hasAllPermission) {
          // Avoid infinite redirection to home if it is being opened from home
          if (!isHomePage) {
            router.push({
              pathname: Pages.HOME,
              query: {
                unauthorized: true,
              },
            });
          }
        } else {
          setIsAuthorized(true);
        }
      }, [
        storePermission,
        activeStore.storeId,
        hasPermission,
        router,
        isHomePage,
        isFetching,
        logoutUser,
      ]);

      // Check against user role
      React.useEffect(() => {
        if (isFetching) {
          return;
        }

        if (storePermission.length === 0) {
          return;
        }

        const { pageRoles } = withAuthParam;

        // Ignore checking if engineeer doesn't supply pageRoles options
        if (!pageRoles) {
          return;
        }

        let hasSomeRole = false;
        for (let i = 0; i < pageRoles.length; i++) {
          if (pageRoles[i].toUpperCase() === userStoreRole) {
            hasSomeRole = true;
            break;
          }
        }

        const hasCommonPermission = hasPermission([PERMISSION_LIST.COMMON]);

        if (!hasCommonPermission) {
          logoutUser({ skipRefetch: true });
        } else if (!hasSomeRole) {
          // Avoid infinite redirection to home if it is being opened from home
          if (!isHomePage) {
            router.push({
              pathname: Pages.HOME,
              query: {
                unauthorized: true,
              },
            });
          }
        } else {
          setIsAuthorized(true);
        }
      }, [
        isFetching,
        storePermission,
        activeStore.storeId,
        hasPermission,
        isHomePage,
        router,
        userStoreRole,
        logoutUser,
      ]);

      if (!isAuthorized) {
        return <LinearProgress />;
      }

      return (
        <>
          <Component {...props} />
          {Modal}
        </>
      );
    };

    return hoistNonReactStatics(ProtectedPage, Component);
  };
}
